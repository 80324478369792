@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500&family=Noto+Sans+JP:wght@100..900&family=Noto+Sans+TC:wght@100..900&family=Noto+Serif+JP:wght@200..900&family=Noto+Serif+TC:wght@200..900&display=swap');

$global-font-family: "Noto Sans TC", "Noto Sans JP", sans-serif;
$header-title-font-family: "Noto Serif TC", "Noto Serif JP",serif;
$header-title-font-size: 1.6em;
$toc-title-font-size: 1rem;
$toc-content-font-size: 0.8rem;
$blockquote-color:#F8F8F8;
$blockquote-bg-color:#F8F8F8;
$cookie-background-color: #77cac2;
