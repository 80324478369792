.categories-card,
.author-card,
.series-card {
  margin: 0 auto;
  margin-top: 3rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 1.6rem;

  .card-item {
    font-size: 0.875rem;
    text-align: left;
    width: 45%;
    display: flex;
    align-items: flex-start;
    margin-top: 2rem;
    min-height: 10rem;
    padding: 0 2%;
    position: relative;

    .card-item-wrapper {
      width: 100%;
      overflow: hidden;

      .card-item-title {
        font-size: 1.2rem;
        font-weight: bold;
        display: inline-block;
        margin-top: 1rem;
        margin-bottom: 0.75rem;
      }

      span {
        float: right;
        padding-right: 1rem;
      }
    }
  }
}

.archive-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  margin: 0.25rem 0 0.25rem 1.5rem;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.archive-item-link {
  min-width: 10%;
  overflow: hidden;
  text-overflow: ellipsis;

  color: $global-link-color;

  &:hover {
    color: $global-link-hover-color;
    background-color: transparent;
  }
}

.archive-item-date {
  text-align: right;
  color: $global-font-secondary-color;
  font-variant-numeric: tabular-nums;
  min-width: fit-content;
  margin-left: auto;
}

.more-post {
  text-align: right;
}
