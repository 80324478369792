// 文章
p {
  padding: 0 0 1em;
}

// 分隔線
hr {
  margin: 1em 0;
}

.copyright-divider {
  border: 0;
  padding-top: 1px;
  background: linear-gradient(to right, transparent, #d0d0d5, transparent);
}

.pagination-divider{
    margin: 2rem 0;
}

.dot-divider{
    position: relative;
    border-top: 3px dashed $global-border-color;
    border-bottom: none;
}

.post-info {
  border-bottom: none !important;
}

// 標題字
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Noto Serif TC", "Noto Serif JP", serif;
  font-display: optional;
  font-style: normal !important;
}

.content > h2,
.content > h3,
.content > h4,
.content > h5,
.content > h6 {
  font-family: "Noto Serif TC", "Noto Serif JP", serif;
  font-display: optional;
  font-weight: 600;
  padding: 1em 0 0;
}

time,
footer,
h3.group-title,
h3.card-item-title,
a.menu-item,
a.more-single-link,
.archive-item-date,
.header-title,
.suggestion-date,
h1.home-title {
  font-family: "EB Garamond", serif;
  font-display: optional;
}

.single-title {
  text-align: left !important;
}

.content > strong {
  font-weight: 600 !important;
}

// 副標題
h2.single-subtitle {
  text-align: left !important;
}

h2.single-subtitle::before {
  content: "──「";
}

h2.single-subtitle::after {
  content: "」";
}

h2.toc-title {
  margin: 0 !important;
}

// 引言

blockquote {
  border-left: 0.5rem solid #adadad !important;
}

blockquote:before {
  color: var(--grey-l1);
  content: "\201C";
  font-size: 3em;
  line-height: 0em;
  vertical-align: -1em;
}

// 圖片

// .featured-image img {
//   display: none !important;
// }

figure > img {
  max-height: 500px;
  height: 500px;
  width: auto;
  margin: 1em 0 !important;
}

// 目錄

[theme="dark"] #toc-auto {
  border-left-color: #6f6a6a;
}

// Categories
.card-item {
  width: 100% !important;
}

.categories-card {
  margin: 0 !important;
}

// @media only screen and (max-width: 1000px) {
//     .toc{
//         display: none !important;
//     }
//     .content{
//         width: 100%;
//     }
//  }
